import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Navbar from '../components/Navbar'
import './News.css'

const API_BASE_URL = 'https://api.makesomenoise.kr'

interface Post {
  id: number
  category: string
  title: string
  content: string
  image_url: string | null
  views: number
  created_at: string
}

const News: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([])
  const [isWriting, setIsWriting] = useState(false)
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [content, setContent] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 15

  const fetchArticles = async (page: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/fetchArticles?page=${page}`)
      const data = await response.json()
      // Sort the articles by id in ascending order
      setPosts(data.articles.sort((a: any, b: any) => a.id - b.id))
    } catch (error) {
      console.error('Error fetching articles:', error)
    }
  }

  const insertArticle = async () => {
    const newPost = {
      category,
      title,
      content,
      image_url: null
    }

    try {
      const response = await fetch(`${API_BASE_URL}/insertArticle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newPost)
      })

      if (response.ok) {
        const data = await response.json()
        const addedPost: Post = {
          id: data.id,
          category,
          title,
          content,
          image_url: null,
          views: 0,
          created_at: new Date().toISOString()
        }
        setPosts([addedPost, ...posts])
        setTitle('')
        setContent('')
        setIsWriting(false)
      } else {
        console.error('Error inserting article:', response.statusText)
      }
    } catch (error) {
      console.error('Error inserting article:', error)
    }
  }

  useEffect(() => {
    fetchArticles(currentPage)
  }, [currentPage])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    insertArticle()
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
    const formattedDate = new Date(dateString)
      .toLocaleDateString('ko-KR', options)
      .replace(/\./g, '-')
      .replace(/ /g, '')

    // 마지막에 '-'가 있다면 제거
    return formattedDate.endsWith('-') ? formattedDate.slice(0, -1) : formattedDate
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <h1>소식</h1>
        {!isWriting && (
          <>
            <div className="board">
              <div className="board_list" id="board_list">
                <table>
                  <colgroup>
                    <col style={{width: '10%'}} />
                    <col style={{width: '60%'}} />
                    <col style={{width: '15%'}} />
                    <col style={{width: '15%'}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">번호</th>
                      <th scope="col" className="title">
                        제목
                      </th>
                      <th scope="col">날짜</th>
                      <th scope="col">조회 수</th>
                    </tr>
                  </thead>
                  <tbody>
                    {posts.map((post, index) => (
                      <tr style={{padding: '10px'}} key={post.id}>
                        {/* Use post.id or index + 1 for numbering */}
                        <td className="no">{index + 1}</td>
                        <td className="title">
                          <Link to={`/news/${post.id}`}>{post.title}</Link>
                        </td>
                        <td className="time">{formatDate(post.created_at)}</td>
                        <td className="readNum">{post.views}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="list_footer">
                <div className="pagination">
                  <a href="#!" onClick={() => paginate(1)}>
                    &laquo; 첫 페이지
                  </a>
                  {Array.from(
                    {length: Math.ceil(posts.length / postsPerPage)},
                    (_, i) => (
                      <a
                        key={i + 1}
                        href="#!"
                        onClick={() => paginate(i + 1)}
                        className={currentPage === i + 1 ? 'active' : ''}>
                        {i + 1}
                      </a>
                    )
                  )}
                  <a
                    href="#!"
                    onClick={() => paginate(Math.ceil(posts.length / postsPerPage))}>
                    끝 페이지 &raquo;
                  </a>
                  {/* <button className="write-button" onClick={() => setIsWriting(true)}>
                  글쓰기
                </button> */}
                </div>
              </div>
            </div>
          </>
        )}
        {isWriting && (
          <form onSubmit={handleSubmit} className="news-form">
            <div>
              <label>카테고리:</label>
              <input
                type="text"
                value={category}
                onChange={e => setCategory(e.target.value)}
                required
              />
            </div>
            <div>
              <label>제목:</label>
              <input
                type="text"
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
              />
            </div>
            <div>
              <label>내용:</label>
              <textarea
                value={content}
                onChange={e => setContent(e.target.value)}
                required
              />
            </div>
            <button type="submit">게시하기</button>
            <button type="button" onClick={() => setIsWriting(false)}>
              취소
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export default News
