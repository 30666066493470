import React, {useState, useEffect, ChangeEvent} from 'react'
import axios from 'axios'
import './Album.css'
import {useNavigate} from 'react-router-dom'

import Navbar from '../components/Navbar'
import {BASE_URL} from '../const/const'

interface Picture {
  id: number
  title: string
  content: string
  thumbnail: string
  created_at: string
}

const Album: React.FC = () => {
  const [pictures, setPictures] = useState<Picture[]>([])
  const [loading, setLoading] = useState(true)
  const [isWriting, setIsWriting] = useState(false)
  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [images, setImages] = useState<File[]>([])
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const navigate = useNavigate()

  // 서버에서 이미지 데이터 로드
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/fetchAlbumPosts`)
        if (Array.isArray(response.data)) {
          setPictures(response.data)
        } else {
          setPictures([])
          console.error('API response is not an array:', response.data)
        }
      } catch (error) {
        console.error('Error fetching album posts:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleWriteClick = () => {
    setIsWriting(true)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files)
      const filesUrls = filesArray.map(file => URL.createObjectURL(file))
      setImages(filesArray)
      setImageUrls(prevImages => prevImages.concat(filesUrls))
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      // 1. 앨범 포스트 생성
      const postResponse = await axios.post(BASE_URL + '/insertAlbumPost', {
        title,
        content
      })

      const postId = postResponse.data.id
      const formData = new FormData()
      formData.append('postId', postId.toString())

      images.forEach((image, index) => {
        formData.append('images', image, `image${index}.jpg`)
      })

      // 2. 이미지 업로드
      const uploadResponse = await axios.post(BASE_URL + '/uploadImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      const newPicture: Picture = {
        id: postId,
        title,
        content,
        thumbnail: `${BASE_URL}/images/${postId}/1.jpg`,
        created_at: new Date().toISOString()
      }

      setPictures([newPicture, ...pictures])
      setTitle('')
      setContent('')
      setImages([])
      setImageUrls([])
      setIsWriting(false)
    } catch (error) {
      console.error('Error uploading images or creating post:', error)
      // 사용자에게 오류 알림 추가
      alert('이미지 업로드 또는 게시물 작성 중 오류가 발생했습니다.')
    }
  }

  if (loading) {
    return <div>Loading ...</div>
  }

  return (
    <div>
      <Navbar />
      <div className="album-container">
        {!isWriting && (
          <>
            {pictures.map(picture => (
              <div
                key={picture.id}
                className="album-item"
                onClick={() => navigate(`/album/${picture.id}`)}>
                <div className="album-image-wrapper">
                  <img
                    key={picture.id}
                    src={BASE_URL + picture.thumbnail}
                    alt={picture.title}
                    className="album-image"
                  />
                </div>
                <div className="album-details">
                  <p className="album-title">{picture.title}</p>
                  {/* <p className="album-content">{picture.content}</p> */}
                </div>
              </div>
            ))}
            <button className="floating-button" onClick={handleWriteClick}>
              글쓰기
            </button>
          </>
        )}
        {isWriting && (
          <form onSubmit={handleSubmit} className="album-form">
            <div>
              <label>제목:</label>
              <input
                type="text"
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
              />
            </div>
            <div>
              <label>설명:</label>
              <textarea
                value={content}
                onChange={e => setContent(e.target.value)}
                required
              />
            </div>
            <div>
              <label>이미지 업로드:</label>
              <input type="file" multiple onChange={handleFileChange} />
            </div>
            {imageUrls.map((url, index) => (
              <img
                key={`${url}-${index}`}
                src={url}
                alt={`preview ${index}`}
                className="image-preview"
              />
            ))}
            <button type="submit">게시하기</button>
            <button type="button" onClick={() => setIsWriting(false)}>
              취소
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export default Album
