// CreatePost.tsx

import React, {useState} from 'react'
import './CreatePost.css' // CSS 파일 임포트

const API_BASE_URL = 'https://api.makesomenoise.kr'

const CreatePost: React.FC = () => {
  const [category, setCategory] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [files, setFiles] = useState<FileList | null>(null)
  const [uploading, setUploading] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setUploading(true)

    try {
      // 게시물 생성
      const createResponse = await fetch(`${API_BASE_URL}/insertArticle`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({category, title, content}) // image_url 제거
      })

      if (!createResponse.ok) {
        throw new Error('게시물 생성에 실패했습니다.')
      }

      const createData = await createResponse.json()
      const postId = createData.id

      if (files && files.length > 0) {
        const formData = new FormData()
        formData.append('postId', postId.toString())
        Array.from(files).forEach(file => {
          formData.append('files', file)
        })

        const uploadResponse = await fetch(`${API_BASE_URL}/uploadFiles`, {
          method: 'POST',
          body: formData
        })

        if (!uploadResponse.ok) {
          throw new Error('파일 업로드에 실패했습니다.')
        }

        const uploadData = await uploadResponse.json()
        console.log('Uploaded files:', uploadData.filePaths)
      }

      // 성공적으로 게시물이 생성되었음을 사용자에게 알림
      alert('게시물이 성공적으로 생성되었습니다!')
      // 폼 초기화 (선택 사항)
      setCategory('')
      setTitle('')
      setContent('')
      setFiles(null)
    } catch (error) {
      console.error('Error creating post:', error)
      alert((error as Error).message)
    } finally {
      setUploading(false)
    }
  }

  return (
    <div className="create-post-container">
      <h2>게시물 작성</h2>
      <form className="create-post-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="category">카테고리:</label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={e => setCategory(e.target.value)}
            required
            placeholder="카테고리를 입력하세요"
          />
        </div>
        <div className="form-group">
          <label htmlFor="title">제목:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            required
            placeholder="제목을 입력하세요"
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">내용:</label>
          <textarea
            id="content"
            value={content}
            onChange={e => setContent(e.target.value)}
            required
            placeholder="내용을 입력하세요"></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="files">첨부파일:</label>
          <input
            type="file"
            id="files"
            multiple
            onChange={e => setFiles(e.target.files)}
            accept=".gpx,.jpg,.png,.pdf" // 필요한 파일 타입 추가
          />
        </div>
        <button type="submit" className="submit-button" disabled={uploading}>
          {uploading ? '업로드 중...' : '게시물 작성'}
        </button>
      </form>
    </div>
  )
}

export default CreatePost
