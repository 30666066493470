import React from 'react'
import Navbar from '../components/Navbar'

import img1 from '../assets/images/about_1.png'
import img2 from '../assets/images/about_2.jpg'
import img3 from '../assets/images/about_3.png'
import img4 from '../assets/images/about_4.png'
import img5 from '../assets/images/about_5.png'
import img6 from '../assets/images/about_6.png'
import img7 from '../assets/images/about_7.png'
import img8 from '../assets/images/about_8.png'
import img9 from '../assets/images/about_9.png'
import img10 from '../assets/images/about_10.png'
import img11 from '../assets/images/about_11.png'
import img12 from '../assets/images/about_12.png'

import btn from '../assets/images/smartstore-btn.png'

const About: React.FC = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <h2 style={{paddingBottom: '15px'}}>소개</h2>
        신안의 숨겨진 보석, 팔금도에서 펼쳐지는 Make Some Noise 트레일 페스티벌은 자연과
        모험, 그리고 섬의 매력을 한데 모은 특별한 경험입니다. 이 페스티벌은 단순한
        트레킹을 넘어, 팔금도의 경이로운 석양과 함께하는 여정으로, 섬의 아름다움을 온전히
        느낄 수 있는 기회를 제공합니다.
        <img
          src={img1}
          alt="about_1"
          style={{width: '100%', paddingTop: '20px', paddingBottom: '20px'}}
        />
        참가자들은 다양한 코스를 통해 채일봉과 서근등대 등 섬의 상징적인 명소를 탐험하며,
        자연과 하나 되는 순간을 경험하게 됩니다. 더불어, 지역 문화와 연결된 다양한 체험
        활동을 통해 섬의 진정한 매력을 발견할 수 있습니다.
        <img
          src={img2}
          alt="about_2"
          style={{width: '100%', paddingTop: '20px', paddingBottom: '20px'}}
        />
        Make Some Noise는 자연을 사랑하고, 섬의 특별한 풍경을 즐기고자 하는 모든 이들에게
        열려 있습니다. 이곳에서 새로운 사람들과 소통하고, 잊지 못할 추억을 만들어가세요.
        신안의 풍경과 함께 울려 퍼질 여러분의 소리를 기다립니다.
        <div style={{paddingTop: '50px'}} />
        <img src={img3} alt="about_3" style={{width: '100%', paddingTop: '20px'}} />
        <img src={img4} alt="about_4" style={{width: '100%', paddingTop: '5px'}} />
        <img src={img5} alt="about_5" style={{width: '100%', paddingTop: '5px'}} />
        <img src={img6} alt="about_6" style={{width: '100%', paddingTop: '5px'}} />
        <img src={img7} alt="about_7" style={{width: '100%', paddingTop: '5px'}} />
        <img
          src={img8}
          alt="about_8"
          style={{width: '100%', paddingTop: '5px', paddingBottom: '20px'}}
        />
        <img src={img9} alt="about_9" style={{width: '100%', paddingTop: '5px'}} />
        <img src={img10} alt="about_10" style={{width: '100%', paddingTop: '5px'}} />
        <img src={img11} alt="about_11" style={{width: '100%', paddingTop: '5px'}} />
        <img src={img12} alt="about_12" style={{width: '100%', paddingTop: '5px'}} />
        <a
          href="https://m.smartstore.naver.com/co77iri/products/10806748600"
          target="_blank">
          <img src={btn} alt="예약페이지 바로가기 버튼" style={{width: '100%'}} />
        </a>
        <div style={{paddingTop: '80px'}} />
      </div>
    </div>
  )
}

export default About
