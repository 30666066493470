import React from 'react'
import './Home.css'
import Navbar from '../components/Navbar'

import poster from '../assets/images/msnp-bg.jpg'
// import bg from '../assets/images/bg1.png'
// import bg from '../assets/images/bg2.png'
// import bg from '../assets/images/bg-remove.png'
// import bg from '../assets/images/bg-remove1.png'
// import bg from '../assets/images/bg-remove2.png'
// import bg from '../assets/images/bg-remove3.png'
import bg from '../assets/images/bg-remove4.png'

const Home: React.FC = () => {
  return (
    <div>
      <Navbar />
      <div className="home-container">
        {/* <div className="links-container">
          <a href="/registration">회원가입</a>
          <a href="/about">소개</a>
          <a href="/news">소식</a>
          <a href="/album">앨범</a>
          <a href="/contact">문의</a>
        </div> */}
        <img
          className="logo"
          src={bg}
          alt="팔금도 트래킹 대회 로고"
          style={{paddingBottom: '30px'}}
        />
        {/* <img className="logo" src={poster} alt="팔금도 트래킹 대회 로고" /> */}
      </div>
    </div>
  )
}

export default Home
