import React from 'react'
import './Contact.css'

import Navbar from '../components/Navbar'

const Contact: React.FC = () => {
  return (
    <div>
      <Navbar />
      <div className="contact-page">
        <div className="contact-container">
          <h1>문의</h1>
          <p>트래킹 참가권 및 관련 문의는 다음을 이용해 주세요:</p>
          <p>
            <strong>이메일:</strong>{' '}
            <a href="mailto:specialmotion@gmail.com">specialmotion@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Contact
