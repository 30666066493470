import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Registration from './pages/Registration'
import News from './pages/News'
import Contact from './pages/Contact'
import PostDetail from './pages/PostDetail'
import Album from './pages/Album'
import AlbumPost from './pages/AlbumPost'
import CreatePost from './pages/CreatePost'

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<PostDetail />} />
        <Route path="/album" element={<Album />} />
        <Route path="/album/:id" element={<AlbumPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/write" element={<CreatePost />} />
      </Routes>
    </Router>
  )
}

export default App
