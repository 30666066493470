// import React, {useState, useRef, useEffect} from 'react'
// import {Link} from 'react-router-dom'
// import MenuIcon from '@mui/icons-material/Menu'
// import logo from '../assets/images/logo-removebg.png'
// import './Navbar.css'
import React from 'react'

const Navbar: React.FC = () => {
  return (
    <div className="links-container" style={{paddingTop: '30px', paddingBottom: '30px'}}>
      <a href="/">홈</a>
      <a href="/registration">가입</a>
      <a href="/about">소개</a>
      <a href="/news">소식</a>
      <a href="/album">앨범</a>
      <a href="/contact">문의</a>
    </div>
  )
}

export default Navbar
