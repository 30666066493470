import React, {useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import Navbar from '../components/Navbar'
import './Registration.css'
import {BASE_URL} from '../const/const'

const Registration: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [nickname, setNickname] = useState('')
  const [email, setEmail] = useState('')
  const [emailDomain, setEmailDomain] = useState('gmail.com')
  const [customEmailDomain, setCustomEmailDomain] = useState('')
  const [isSubscribed, setIsSubscribed] = useState('yes')

  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const navigate = useNavigate()

  const handleEmailDomainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'custom') {
      setEmailDomain('')
    } else {
      setEmailDomain(e.target.value)
      setCustomEmailDomain('')
    }
  }

  const validatePassword = (password: string) => {
    const hasLetter = /[a-zA-Z]/.test(password)
    const hasNumber = /[0-9]/.test(password)
    return hasLetter && hasNumber && password.length >= 8
  }

  const isPasswordValid = validatePassword(password)
  const isConfirmPasswordValid = confirmPassword === password

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!isPasswordValid) {
      alert('비밀번호는 8자리 이상이어야 하며, 영어와 숫자를 포함해야 합니다.')
      return
    }

    if (!isConfirmPasswordValid) {
      alert('비밀번호와 비밀번호 확인이 일치하지 않습니다.')
      return
    }

    try {
      const recaptchaToken = await recaptchaRef.current?.executeAsync()

      if (!recaptchaToken) {
        alert('reCAPTCHA 검증에 실패했습니다. 다시 시도해 주세요.')
        recaptchaRef.current?.reset() // 검증 실패 후 reset
        return
      }

      const registrationData = {
        username,
        password,
        name,
        nickname,
        email: `${email}@${emailDomain || customEmailDomain}`,
        isSubscribed: isSubscribed === 'yes',
        recaptchaToken
      }

      const response = await fetch(`${BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(registrationData)
      })

      const result = await response.json()

      if (response.ok) {
        alert('회원가입이 완료되었습니다.')
        navigate('/') // 홈으로 이동
        recaptchaRef.current?.reset() // 회원가입 성공 후 reset
      } else {
        alert('회원가입에 실패했습니다.')
        recaptchaRef.current?.reset() // 회원가입 실패 후 reset
      }
    } catch (error) {
      console.error('Error during registration:', error)
      alert('회원가입 중 오류가 발생했습니다.')
    }
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        {/* <Navbar /> */}
        <form className="registration-form" onSubmit={handleSubmit}>
          <h2 style={{paddingBottom: '15px'}}>회원가입</h2>

          <div className="form-group">
            <label>아이디</label>
            <input
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>비밀번호</label>
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            {!isPasswordValid && (
              <small className="error">
                *영어, 숫자를 포함한 8자 이상의 비밀번호를 입력하세요
              </small>
            )}
          </div>

          <div className="form-group">
            <label>비밀번호 확인</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
            {!(confirmPassword.length > 0) ||
              (!isConfirmPasswordValid && (
                <small className="error">비밀번호가 다릅니다</small>
              ))}
          </div>

          <div className="form-group">
            <label>이름</label>
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>닉네임</label>
            <input
              type="text"
              value={nickname}
              onChange={e => setNickname(e.target.value)}
              required
            />
          </div>

          <div className="form-group email-group">
            <label>이메일</label>
            <div className="email-input-group">
              <input
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
              <span>@</span>
              <select
                value={emailDomain || customEmailDomain}
                onChange={handleEmailDomainChange}
                required>
                <option value="gmail.com">gmail.com</option>
                <option value="naver.com">naver.com</option>
                <option value="daum.net">daum.net</option>
                <option value="custom">직접 입력</option>
              </select>
              {emailDomain === '' && (
                <input
                  type="text"
                  value={customEmailDomain}
                  onChange={e => setCustomEmailDomain(e.target.value)}
                  required
                  placeholder="도메인을 입력하세요"
                />
              )}
            </div>
          </div>

          <div style={{paddingBottom: '20px'}} className="form-group">
            <label style={{paddingTop: '10px'}}>메일링 가입 여부</label>
            <div className="subscription-options">
              <label>
                <input
                  type="radio"
                  value="yes"
                  checked={isSubscribed === 'yes'}
                  onChange={() => setIsSubscribed('yes')}
                />
                예
              </label>
              <label>
                <input
                  type="radio"
                  value="no"
                  checked={isSubscribed === 'no'}
                  onChange={() => setIsSubscribed('no')}
                />
                아니오
              </label>
            </div>
          </div>

          {/* ReCAPTCHA */}
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lesh0cqAAAAAM173zkdjO4pB9W6F1f5sPbi4XRy"
            size="invisible"
          />

          <button type="submit">회원가입</button>
        </form>
      </div>
    </div>
  )
}

export default Registration
