// PostDetail.tsx

import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Navbar from '../components/Navbar'
import './PostDetail.css'

interface Post {
  id: number
  category: string
  title: string
  content: string
  views: number
  created_at: string
}

interface File {
  path: string
  name: string
}

const API_BASE_URL = 'https://api.makesomenoise.kr'

const PostDetail: React.FC = () => {
  const {id} = useParams<{id: string}>() // URL에서 postId를 가져옴
  const [post, setPost] = useState<Post | null>(null) // 게시물 상태
  const [files, setFiles] = useState<File[]>([]) // 파일 상태
  const [images, setImages] = useState<string[]>([]) // 이미지 경로 상태
  const [loading, setLoading] = useState(true) // 로딩 상태 관리
  const [error, setError] = useState<string | null>(null) // 에러 상태 관리

  // 게시글 데이터 불러오기
  const fetchPost = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/fetchNewsPost/${id}`)
      if (!response.ok) {
        throw new Error('게시글을 불러오는 데 실패했습니다.')
      }
      const data = await response.json()
      setPost(data)
    } catch (err) {
      setError((err as Error).message)
    } finally {
      setLoading(false)
    }
  }

  // 이미지 데이터 불러오기
  const fetchImages = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/fetchNewsImages/${id}`)
      const data = await response.json()
      setImages(data)
    } catch (err) {
      console.error('Error fetching images:', err)
    }
  }

  // 첨부파일 데이터 불러오기
  const fetchFiles = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/fetchNewsFiles/${id}`)
      if (!response.ok) {
        throw new Error('첨부파일을 불러오는 데 실패했습니다.')
      }
      const data: File[] = await response.json()
      setFiles(data)
    } catch (err) {
      console.error('Error fetching files:', err)
    }
  }

  useEffect(() => {
    // 게시글과 이미지를 모두 불러옴
    fetchPost()
    fetchImages()
    fetchFiles() // 첨부파일도 불러오기
  }, [id])

  if (loading) {
    return <div>로딩 중...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  if (!post) {
    return <div>게시물을 찾을 수 없습니다.</div>
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <h1>{post.title}</h1>
        {/* <p>{post.content}</p> */}
        {/* <p>조회수: {post.views}</p> */}
        {/* <p>작성일: {new Date(post.created_at).toLocaleDateString('ko-KR')}</p> */}

        {/* 서버에서 불러온 이미지 렌더링 */}

        {/* 첨부파일 다운로드 링크 */}
        {files.length > 0 && (
          <div>
            <h3>첨부파일</h3>
            <ul>
              {files.map((file, index) => (
                <li key={index}>
                  <a href={`${API_BASE_URL}${file.path}`} download={file.name}>
                    {file.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {images.length > 0 ? (
          images.map((image, index) => (
            <img
              key={index}
              src={`${API_BASE_URL}${image}`} // 서버 이미지 경로
              alt={`news_image_${index}`}
              style={{width: '100%', paddingTop: '20px', paddingBottom: '20px'}}
            />
          ))
        ) : (
          <p></p>
        )}

        <p style={{whiteSpace: 'pre-line'}}>{post.content}</p>

        <div style={{paddingTop: '40px'}} />
      </div>
    </div>
  )
}

export default PostDetail
