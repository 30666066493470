import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import './Album.css'
import Navbar from '../components/Navbar'

const BASE_URL = 'https://co77iri.com:8002'

interface Picture {
  id: number
  title: string
  content: string
  thumbnail: string
  created_at: string
}

interface Image {
  url: string
}

const AlbumPost: React.FC = () => {
  const {id} = useParams<{id: string}>()
  const [post, setPost] = useState<Picture | null>(null)
  const [images, setImages] = useState<Image[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postResponse = await axios.get(`${BASE_URL}/fetchAlbumPost/${id}`)
        const imagesResponse = await axios.get(`${BASE_URL}/fetchAlbumImages/${id}`)
        // console.log(imagesResponse)
        setPost(postResponse.data)
        setImages(imagesResponse.data)
      } catch (error) {
        console.error('Error fetching album post:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchPost()
  }, [id])

  if (loading) {
    return <div>Loading ...</div>
  }

  // 링크가 포함된 경우, 정규식을 사용하여 추출
  const renderContentWithLink = (content: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const match = content.match(urlRegex)
    if (match) {
      return (
        <>
          <p>전체 이미지는 아래 링크에서 확인해 주세요:</p>
          <a href={match[0]} target="_blank" rel="noopener noreferrer">
            {match[0]}
          </a>
        </>
      )
    }
    return <p>{content}</p>
  }

  return (
    <div>
      <Navbar />
      <div className="album-container">
        {post && (
          <>
            <h1>{post.title}</h1>
            <div className="album-images">
              {images.map((image, index) => {
                const imgSrc = BASE_URL + image
                // console.log(`Image URL: ${imgSrc}`)
                return <img key={index} src={imgSrc} alt={`album ${id} - ${index}`} />
              })}
            </div>
            {post.content && renderContentWithLink(post.content)}
          </>
        )}
      </div>
    </div>
  )
}

export default AlbumPost
